import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { language } from '../../utils'
import { FiLoader } from 'react-icons/fi'

export default function Price({ price, className, loading }) {
  const { strapi } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          language
          currency
        }
      }
    }
  `)
  if (price === null || price === undefined) return null
  if (loading) return <FiLoader className="h-5 w-5 stroke-1 rotating box-border inline" />
  const locale = strapi.locales.find(l => l.language === language())
  const formatter = new Intl.NumberFormat(locale.language, {
    style: 'currency',
    currency: locale.currency,
    minimumFractionDigits: 0
  })
  return <span className={className}>{formatter.format((price.unit_amount || price.amount || Number(price)) / 100.0)}</span>
}
