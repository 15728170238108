import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

export default function ErrorPage({ message }) {
  return (
    <div className="flex flex-col justify-center items-center opacity-75 text-gray-600 h-screen">
      <FiAlertTriangle className="h-12 w-12 stroke-1 box-border" />
      <div className="font-thin text-3xl">{message}</div>
    </div>
  )
}