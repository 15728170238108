import React from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Loader({ label, progress }) {
  return (
    <div className="flex flex-col justify-center items-center opacity-75 text-gray-600 m-8">
      <FiLoader className="h-12 w-12 stroke-1 rotating box-border" />
      {label && <div className="font-light text-xs italic">{label}</div>}
      {progress && <div className="text-xl -mt-4">{Math.round(progress * 100)} %</div>}
    </div>
  )
}