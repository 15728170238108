import React from 'react'
import moment from 'moment'
import { Elements } from '@stripe/react-stripe-js'
import I18n from './I18n'
import Button from './Button'
import Price from './Price'
import PayEvent from '../PayEvent'
import { FiLoader } from 'react-icons/fi'
moment.locale('en')

const Header = ({ locked, current, paidInvoice, payTrigger, event, stripePromise, upcoming, tryPromo, finalize, advance, succeeded, load }) => {
	return (
		<div
			className="fixed z-100 top-0 w-screen px-2 py-4 lg:py-4 flex justify-center items-center leading-tight bg-pink text-white font-bold text-sm lg:text-xl text-center">
			{current && !(paidInvoice || current.pre_payment_credit_notes_amount > 0) &&
        <p>
          {locked ? <I18n>app-redesign.header.booking</I18n> : <I18n>app-redesign.header.notPaid</I18n>}
          {!locked && event.date ? ` le ${moment(event.date).format('DD/MM/YYYY')}` : ''}
        </p>
			}
			{!current && paidInvoice &&
        <p>
          <I18n>app-redesign.header.paid</I18n> {event.date ? `pour le ${moment(event.date).format('DD/MM/YYYY')}` : ''}
          {load && <FiLoader className="h-8 w-8 stroke-1 rotating box-border inline ml-4" />}
        </p>
			}
			{current && (paidInvoice || current.pre_payment_credit_notes_amount > 0) &&
        <p>
          <I18n>app-redesign.header.partiallyPaid</I18n>&nbsp;<Price price={current.amount_remaining} loading={current.loading} />&nbsp;à payer
        </p>
			}
		</div>
	)
}

export default Header
