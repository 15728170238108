import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import 'moment/locale/fr'
import { language } from '../utils'
import { applyPromo, deletePromo, closeInvoice, chargeInvoice, findEvent, getProducts, refreshInvoice, updateEvent, cancelEvent } from '../api'
import { loadStripe } from '@stripe/stripe-js'
import GoogleMapReact from 'google-map-react'
import I18n from '../components/UI/I18n'
import ErrorPage from '../components/UI/ErrorPage'
import Loader from '../components/UI/Loader'
import Header from '../components/UI/Header'
import Title from '../components/UI/Title'
import HelpCenter from '../components/HelpCenter'
import Options from '../components/Options'
import Summary from '../components/Summary'
import Delivery from '../components/Delivery'
import Customization from '../components/Customization'
import { FiArrowDown, FiImage, FiMenu, FiPlay, FiPlayCircle, FiVideo } from 'react-icons/fi'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import Headers from '../components/UI/Headers'
import Button from '../components/UI/Button'
import NotFound from '../components/NotFound'
import Info from '../components/UI/Info'

moment.locale('fr')

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const innerWidth = () => (typeof window !== `undefined`) ? window.innerWidth : 1024
const match = (location().pathname.match(/event\/(\d+)_(.+)/) || [])
const eventId = match[1]
const token = match[2]

const payTrigger = {}

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export default function Event() {
  const { stripePublic } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          stripePublic
          language
        }
      }
    }
  `).strapi.locales.find(l => l.language === language())
  const [stripePromise, setStripePromise] = useState()
  const [width, setWidth] = useState(innerWidth())
  const [products, setProducts] = useState([])
  const [event, setEvent] = useState(null)
  const options = products && products.filter(p => p.upsale)
  const shippings = products && products.filter(p => p.shipping)
  const [load, setLoad] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [cancelPanel, setCancelPanel] = useState(false)
  const [unsubscribe, setUnsubscribe] = useState(false)

  useEffect(() => {
    const handleResize = debounce(() => setWidth(innerWidth()), 500)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    setStripePromise(loadStripe(stripePublic))
  }, [])

  useEffect(() => {
    setCancelPanel(location().search.includes('unsubscribe=true'))
  }, [location])

  useEffect(() => {
    if (event && event.stripeCache) return
    if (event && event.error) return
    if (products.length === 0)
      getProducts().then(p => setProducts(p))
    findEvent(eventId, token).then(e => setEvent(e))
  }, [event])

  if (event && event.error) return <ErrorPage message={event.message} />
  if (event === null || event.stripeCache === null) return <Loader />

  const daysDifference = Math.floor(moment(new Date(event.date)).diff(moment(), 'd', true)) + 1
  const ended = moment(Date.now()).isAfter(moment(event.date))
  const locked = (daysDifference <= 7 || moment(Date.now()).isAfter(moment(event.date))) && !event.unlock
  const upcoming = event && event.stripeCache && event.stripeCache.upcoming
  const invoices = event.stripeCache && event.stripeCache.invoices || []
  const paidInvoice = event.stripeCache && invoices.find(i => i.status === 'paid')
  const current = event.stripeCache && (upcoming || invoices.find(i => i.status === 'draft') || invoices.find(i => i.status === 'open'))

  const loading = async () => {
    if (current) current.loading = true
    setEvent({ ...event })
  }
  const unloading = async () => {
    if (current) current.loading = false
    setEvent({ ...event })
  }
  const tryPromo = async code => {
    try {
      loading()
      return setEvent(await applyPromo(event, code))
    } catch (error) {
      console.log(error)
      unloading()
      return Promise.reject(error)
    }
  }
  const finalize = async () => {
    return closeInvoice(event)
  }
  const advance = async () => {
    return chargeInvoice(event, 15000)
  }
  const onCreaSaved = async crea => {
    return setEvent({ ...event, customization: crea })
  }
  const succeeded = async () => {
    loading()
    return setEvent(await refreshInvoice(event))
  }
  const addProduct = async product => {
    product.active = true
    loading()
    return setEvent(await updateEvent(event, { products: [...event.products.map(p => p.id), product.id] }))
  }
  const removeProduct = async product => {
    product.active = false
    loading()
    return setEvent(await updateEvent(event, { products: event.products.filter(p => p.id !== product.id).map(p => p.id) }))
  }
  const switchProducts = async (p1, p2) => {
    loading()
    return setEvent(await updateEvent(event, { products: [...event.products.filter(p => p.id !== p1?.id).map(p => p.id), p2.id] }))
  }
  const setPickup = async pickup => {
    return setEvent(await updateEvent(event, { pickup }))
  }
  const setAddress = async (address, returnAddress) => {
    return setEvent(await updateEvent(event, { address, returnAddress: returnAddress ? { ...address, ...returnAddress } : null }))
  }
  const setCustomerInfo = async (firstname, email, phone) => {
    return setEvent(await updateEvent(event, { firstname: firstname, email: email, phone: phone }))
  }
  const deleteEvent = async event => {
    console.log('event inside cancelEvent', event)
    const response = await cancelEvent(event)
    console.log('cancelEvent response', response)
    setUnsubscribe(true)
  }
  const props = { locked, width, shippings, options, event, current, paidInvoice, payTrigger, upcoming, stripePromise, load, setPickup, setAddress, setLoad, onCreaSaved, setCustomerInfo, addProduct, removeProduct, tryPromo, finalize, advance, succeeded }

  if (cancelPanel)
    return (
      <div className="w-screen h-screen overflow-hidden relative flex flex-col items-center">
        <img src="/logo-magenta.png" alt="Logo" className="w-48 mb-3" />
        <div className="flex flex-col items-center mt-12">
          {!unsubscribe ? (
            <>
              <Title variant="extra-large">
                <I18n>app-redesign.dashboard.greeting</I18n>&nbsp;{event.firstname} 
              </Title>
              <p className="text-3xl mt-8 px-6 recoleta"><I18n>app-redesign.subscription.warningMessage</I18n> {moment(event.date).format('Do MMMM')}?</p>
            </>
          ) : (
            <p className="text-3xl mt-8 px-6 recoleta"><I18n>app-redesign.subscription.unsubscribedMessagePart1</I18n> {moment(event.date).format('Do MMMM')} <I18n>app-redesign.subscription.unsubscribedMessagePart2</I18n></p>
          )
          }
        </div>
        {!unsubscribe && (
          <div className="flex justify-center items-center mt-24">
            <Button
              variant="default"
              className="mx-4 lg:mx-8"
              style={{ color: 'black' }}
              onClick={() => deleteEvent(event)}>
              <I18n>app-redesign.subscription.unsubscribeBtn</I18n>
            </Button>
            <Button
              variant="default"
              className="mx-4 lg:mx-8"
              href={location().pathname + location().search.replace(/&?unsubscribe=true&?/, '')}>
              <I18n>app-redesign.subscription.discardBtn</I18n>
            </Button>
          </div>
        )}
      </div>
    )

  if (event.date && current && moment(Date.now()).isAfter(moment(event.date)) && current.amount_remaining > 0)
    return <NotFound />

  return (
    <div className="relative">
      <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&libraries=places' }} />
      <Header {...props} />
      <Headers />
      <div className="content h-full mx-auto relative w-auto lg:max-w-250" style={{ paddingTop: 80 }}>
        <img src="/logo-magenta.png" alt="Logo" className="w-40 lg:w-56 mb-3 mx-auto" />
        <div className="flex flex-col items-start mb-6 pl-6 lg:pl-0">
          <Title variant="extra-large">
            <I18n>app-redesign.dashboard.greeting</I18n>&nbsp;{event.firstname} 
          </Title>
          <div className="recoleta text-2xl leading-tight">
            <I18n>app-redesign.dashboard.title</I18n>
            <p>pour le <strong>{moment(event.date).format('DD/MM/YYYY')}</strong></p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-stretch">
          <div
            className="hidden lg:block w-full bg-cover bg-center border-15 border-gray-100 mr-6"
            style={{ backgroundImage: 'url(/image1.jpg)' }} />
          <Summary {...props} deletePromo={async () => setEvent(await deletePromo(event))} />
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-stretch w-full mt-6">
          {!ended &&
            <Delivery
              shippings={shippings} event={event} upcoming={upcoming} products={products}
              switchProducts={switchProducts} setPickup={setPickup} setAddress={setAddress} disabled={locked} />
          }
          <div className="hidden lg:inline-block border-15 border-gray-100 w-full bg-cover bg-center md:mx-6" style={{ backgroundImage: 'url(/image2.jpg)' }} />
          {!ended && <Customization customization={event.customization} saved={onCreaSaved} disabled={locked} />}
          {locked && event.date && moment(event.date).isBefore(moment()) && event.liveEvent && event.liveEvent.gallery &&
            <div className="flex flex-col justify-between items-center text-center w-full md:w-200 p-5 md:ml-6  bg-pink-light flex-shrink-0">
              <Title variant="large">
                🖼️ Galerie photo
              </Title>
              <br/>
              <div className="mt-4">Retrouvez ici l'intégralité de vos photos. <br/> Elles apparaitrons dès que nous aurons récupéré votre borne photo.<br />Vous pouvez partager le lien publique avec tout les participants à votre événement.</div>
              <Info className="mt-4">Conserver le lien d'administration si vous souhaitez supprimer des photos ou télécharger l'intégralité de vos photos</Info>
              <div className="flex mt-4">
                <Button variant="secondary" className="mx-4" href={`https://live.wizito.com/galleries/${event.liveEvent.gallery.url_name}`}>Lien publique</Button>
                <Button variant="secondary" className="mx-4" href={`https://live.wizito.com/galleries/${event.liveEvent.gallery.url_name}/admin/${event.liveEvent.gallery.token}`}>Administration</Button>
              </div>
            </div>
          }
        </div>
        {!locked &&
          <Options options={options} event={event} addProduct={addProduct} removeProduct={removeProduct} current={current} setLoad={setLoad} width={width} />
        }
      </div>
      <div className="py-10 mt-20 bg-pink-light">
        <div className="h-full mx-auto w-auto lg:max-w-250 ">
          <div className="text-center lg:text-left">
            <Title variant="extra-large">
              <div className="z-10"><I18n>app-redesign.how.title</I18n></div>
            </Title>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-between w-full mt-10">
            <iframe className=" h-84 flex-shrink-0 max-w-full border-15 border-white" width="560" height="315" src="https://www.youtube.com/embed/bzzEqUXZiBg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            <div className="flex flex-col justify-between items-center w-full lg:w-auto lg:mx-6">
              <a className="flex flex-col justify-center items-center text-black text-2xl w-full h-40 my-6 px-2 text-center leading-tight lg:my-0 bg-white " href="https://www.youtube.com/watch?v=E3EJGqgyrOE" target="_blank">
                <FiPlayCircle className="w-10 h-10 mb-4 stroke-1" />
                <Title variant="medium" style={{ color: '#F9F4EC' }}>
                  <I18n>app-redesign.how.videos</I18n>
                </Title>
              </a>
              <HelpCenter showHelp={showHelp} setShowHelp={setShowHelp} />
            </div>
            <div className="hidden lg:block border-15 border-white w-full h-84 bg-cover" style={{ backgroundImage: 'url(/image9.jpg)' }} />
          </div>
          <div className="flex flex-col lg:flex-row justify-start items-between w-full mt-6 mb-20">
            <div className="flex flex-col justify-between items-center w-full lg:w-auto">
              <div className="flex flex-col justify-center items-center text-2xl w-full h-48 bg-pink text-white mb-6">
                <Title className="text-2xl"><I18n>app-redesign.how.follow</I18n></Title>
                <div className="flex" style={{ color: '#F9F4EC' }}>
                  <a href="https://www.facebook.com/wizitophotobooth" target="_blank"><FaFacebook className="h-16 w-16 mt-5 mx-3" /></a>
                  <a href="https://www.instagram.com/wizito_party/" target="_blank"><FaInstagram className="h-16 w-16 mt-5 mx-3" /></a>
                </div>
              </div>
              <a className="flex justify-center w-full mt-6 lg:my-0" href="https://www.mariages.net/animation-mariage/wizito-party--e238142" target="_blank">
                <div className="flex justify-between items-center flex-wrap w-10/12 sm:w-100 lg:w-80  text-white">
                  <img className="w-1/2 pr-3 flex-shrink" src="https://www.mariages.net/assets/img/badges/rated/badge-rated-25.png" alt="reviews" />
                  <img className="w-1/2 pl-3 flex-shrink" src="https://cdn1.mariages.net/img/badges/2024/badge-weddingawards_fr_FR.jpg" alt="reviews" />
                </div>
              </a>
            </div>
            <div className="hidden lg:block border-15 border-white w-200 bg-cover mx-6 bg-center" style={{ backgroundImage: 'url(/image8.jpg)' }} />
            <div className="hidden lg:block border-15 border-white w-full bg-cover bg-center" style={{ backgroundImage: 'url(/image10.jpg)' }} />
          </div>
          <p className="text-base text-center text-black">&copy; {moment().format('YYYY')} Wizito</p>
        </div>
      </div>
    </div>
  )
}
